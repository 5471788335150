<template>
    <div class="mt-5 container">
       <WorkHeader :title="$t('message.project.netify.title')" date="2024/08/24"/>
        <div class="row d-flex justify-content-center">
            <div class="col-12">

                <div class="card bg-light border-0 rounded-4 wow fadeIn" data-wow-duration="1.5s">
                    <div class="row">
                        <div class="col-6 d-flex flex-column justify-content-center align-items-center text-center">
                            <h1 class="fw-bold">{{ $t('message.project.netify.app_name') }}</h1>
                            <p class="text-muted">{{ $t('message.project.netify.title') }}</p>
                            <div class="d-none d-md-flex flex-row d-grid gap-2 mt-5 ">
                                <SingleStack :imgSrc="require('@/assets/stackLogo/ios.png')"
                                             stackName="iOS" bgc="#000000"></SingleStack>
                                <SingleStack :imgSrc="require('@/assets/stackLogo/swift.png')"
                                             stackName="Swift" bgc="#fc3f1b"></SingleStack>
                                <SingleStack :imgSrc="require('@/assets/stackLogo/node-js.png')"
                                             stackName="NODE.JS" bgc="#43853d"></SingleStack>
                            </div>
                        </div>
                        <div class="col-6">
                            <img src="@/assets/worksDetail/netify/musicBuddyIOS_matting.png" class="img-fluid">
                        </div>
                    </div>
                </div>

                <!--项目目标卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <div class="row">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center text-center">
                            <h3 class="text-center red-bottom">{{$t('message.project.netify.aim.header')}}</h3>
                            <p class="mt-3">{{$t('message.project.netify.aim.content')}}</p>
                        </div>
                        <div class="col-12 col-lg-6 mt-2 mt-lg-0 d-flex justify-content-center">
                            <table>
                                <tr>
                                    <th>#</th>
                                    <th>{{$t('message.project.stack.type')}}</th>
                                    <th>{{$t('message.project.stack.stack')}}</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>{{$t('message.project.stack.front_end')}}</td>
                                    <td>SwiftUI</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>{{$t('message.project.stack.back_end')}}</td>
                                    <td>Node.js</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>{{$t('message.project.stack.api_testing')}}</td>
                                    <td>Postman</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>{{$t('message.project.stack.editor')}}</td>
                                    <td>XCode</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>{{$t('message.project.stack.version_control')}}</td>
                                    <td>GitHub</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>


                <!--用户界面卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <div class=" mt-3">
                        <h3 class="red-bottom">{{$t('message.project.netify.interface.header')}}</h3>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 order-1 order-md-1">
                            <img src="@/assets/worksDetail/netify/login_matting.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-2">
                            <h3 class="fw-bold">{{$t('message.project.netify.interface.login.header')}}</h3>
                            <p>{{$t('message.project.netify.interface.login.content')}}</p>
                        </div>
                    </div>


                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-1">
                            <h3 class="fw-bold">{{$t('message.project.netify.interface.recommendation.header')}}</h3>
                            <p>{{$t('message.project.netify.interface.recommendation.content')}}</p>
                        </div>
                        <div class="col-12 col-md-6 order-1 order-md-2">
                            <div class="row d-flex flex-row justify-content-center">
                                <div class="col-6"><img src="@/assets/worksDetail/netify/dashboard.png" class="img-fluid"></div>
                                <div class="col-6"><img src="@/assets/worksDetail/netify/playlist.png" class="img-fluid"></div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 order-1 order-md-1">
                            <div class="row d-flex flex-row justify-content-center">
                                <div class="col-6"><img src="@/assets/worksDetail/netify/player.png" class="img-fluid"></div>
                                <div class="col-6"><img src="@/assets/worksDetail/netify/lyric.png" class="img-fluid"></div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-2">
                            <h3 class="fw-bold">{{$t('message.project.netify.interface.lyric.header')}}</h3>
                            <p>{{$t('message.project.netify.interface.lyric.content')}}</p>
                        </div>
                    </div>


                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-1">
                            <h3 class="fw-bold">{{$t('message.project.netify.interface.metadata.header')}}</h3>
                            <p>{{$t('message.project.netify.interface.metadata.content')}}</p>
                        </div>
                        <div class="col-10 col-md-4 order-1 order-md-2 mx-auto">
                            <img src="@/assets/worksDetail/netify/metadata.png" class="img-fluid">
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-10 col-md-4 order-1 order-md-1 mx-auto">
                            <img src="@/assets/worksDetail/netify/profile.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-2">
                            <h3 class="fw-bold">{{$t('message.project.netify.interface.report.header')}}</h3>
                            <p>{{$t('message.project.netify.interface.report.content')}}</p>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
       
</template>

<script>
import WorkHeader from '@/components/WorkHeader';
import SingleStack from "@/components/SingleStack.vue";

export default {
    components: {
        SingleStack,
        WorkHeader,
    },

};
</script>

<style lang="css" scoped>

table {
    border-collapse: collapse;
    width: 100%;
}
th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}
th {
    background-color: #f2f2f2;
}

li{
    list-style-type: circle;
}

</style>