<template>
    <nav class="navbar navbar-expand-lg p-0 m-0 sticky-top">
        <div class="container-fluid">
            <router-link
                class="navbar-brand fs-3 fw-bold primary text-decoration-none d-flex align-items-center"
                to="/"
            >
                <img src="@/assets/logo/logo2.png" style="width: 60px" />
                <span class="d-none d-sm-inline">TerenzSpace</span>
            </router-link>

            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsibleNavbar"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div
                class="collapse navbar-collapse flex-row-reverse"
                id="collapsibleNavbar"
            >
                <ul class="navbar-nav">
                    <li class="nav-item" v-for="(route, index) in routes" :key="index">
                        <router-link
                            :to="route.path"
                            class="item"
                            :class="{ selected: isActive(route.path) }"
                        >{{ route.name }}</router-link
                        >
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0);" class="item" @click="toggleLanguage">
                            <img :src="currentFlag" alt="Language Flag" style="width: 32px"/>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>


<script>
export default {
    data() {
        return {
            routes: [],
            flags: {
                en: require('../assets/country/en.png'),
                zh: require('../assets/country/cn.png'),
            },
            currentLanguage: 'zh', // 默认语言为中文
        };
    },
    computed: {
        currentFlag() {
            return this.flags[this.currentLanguage];
        }
    },
    created() {
        this.updateRouteNames();
    },
    methods: {
        isActive(path) {
            return this.$route.path === path;
        },
        toggleLanguage() {
            if (this.currentLanguage === 'zh') {
                this.currentLanguage = 'en'; // 切换到英文
                this.$i18n.locale = 'en'; // 切换语言为英文
            } else {
                this.currentLanguage = 'zh'; // 切换到中文
                this.$i18n.locale = 'zh'; // 切换语言为中文
            }
            this.updateRouteNames();
            this.toggleNavBar()
        },
        toggleNavBar(){
            const navbarToggler = document.querySelector(".navbar-toggler");
            if (navbarToggler && navbarToggler.getAttribute("aria-expanded") === "true") {
                navbarToggler.click();
            }
        },
        updateRouteNames() {
            this.routes = [
                { path: "/", name: this.$t('message.navbar.about') },
                { path: "/works", name: this.$t('message.navbar.project') },
                // { path: "/blogs", name: this.$t('message.navbar.blog') },
                { path: "/cat", name: this.$t('message.navbar.kitty') },
                { path: "/cocktail", name: this.$t('message.navbar.cocktail') },
                { path: "/contact", name: this.$t('message.navbar.contact') },
            ];
        }
    },
    watch: {
        $route() {
            this.toggleNavBar()
        },
    },
};
</script>

<style lang="css" scoped>

.item {
    font-size: 20px;
    margin-inline: 14px;
}

.navbar-nav .nav-item {
    padding: 10px;
}

.selected {
    border-bottom: 3px solid #FF6666;
}
</style>