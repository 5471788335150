// src/i18n.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locales/en'
import zh from './locales/zh'


Vue.use(VueI18n)

// 导入语言包
const messages = {
    en,
    zh,
}

// 创建 VueI18n 实例
const i18n = new VueI18n({
    locale: 'zh', // 设置默认语言
    messages, // 设置语言包
})

export default i18n
