<template>
    <div id="HealthyExpertView" class="mt-5 container-lg">
       <WorkHeader :title="$t('message.project.healthyExpert.title')" date="2023/07/14"/>
        <div class="row d-flex justify-content-center">
            <div class="col-12 p-0 m-0">

                <div class="card bg-light border-0 rounded-4 wow fadeIn" data-wow-duration="1.5s">
                    <div class="row">
                        <div class="col-6 d-flex flex-column justify-content-center align-items-center text-center">
                            <h1 class="fw-bold">{{ $t('message.project.healthyExpert.app_name') }}</h1>
                            <p class="text-muted">{{ $t('message.project.healthyExpert.title') }}</p>
                            <div class="d-none d-md-flex flex-row d-grid gap-2 mt-5 ">
                                <SingleStack :imgSrc="require('@/assets/stackLogo/android.png')"
                                             stackName="ANDROID" bgc="#3ddc84"></SingleStack>
                                <SingleStack :imgSrc="require('@/assets/stackLogo/kotlin.png')"
                                             stackName="KOTLIN" bgc="#0095d5"></SingleStack>
                                <SingleStack :imgSrc="require('@/assets/stackLogo/mysql.png')"
                                             stackName="MYSQL" bgc="#00000f"></SingleStack>
                                <SingleStack :imgSrc="require('@/assets/stackLogo/node-js.png')"
                                             stackName="NODE.JS" bgc="#43853d"></SingleStack>
                            </div>
                        </div>
                        <div class="col-6">
                            <img src="@/assets/worksDetail/healthyExpert/healthyExpert.png" class="img-fluid">
                        </div>
                    </div>
                </div>

                <!--项目目标卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <div class="row">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center text-center">
                            <h3 class="text-center red-bottom">{{$t('message.project.healthyExpert.aim.header')}}</h3>
                            <p class="mt-3">{{$t('message.project.healthyExpert.aim.content')}}</p>
                        </div>
                        <div class="col-12 col-lg-6 mt-2 mt-lg-0 d-flex justify-content-center">
                            <table>
                                <tr>
                                    <th>#</th>
                                    <th>{{$t('message.project.healthyExpert.aim.stack.type')}}</th>
                                    <th>{{$t('message.project.healthyExpert.aim.stack.stack')}}</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>{{$t('message.project.healthyExpert.aim.stack.front_end')}}</td>
                                    <td>kotlin、Jetpack、XML</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>{{$t('message.project.healthyExpert.aim.stack.back_end')}}</td>
                                    <td>Node.js、Express.js</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>{{$t('message.project.healthyExpert.aim.stack.api_testing')}}</td>
                                    <td>Postman</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>{{$t('message.project.healthyExpert.aim.stack.database')}}</td>
                                    <td>MySQL</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>{{$t('message.project.healthyExpert.aim.stack.server')}}</td>
                                    <td>Linux Ubuntu</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>{{$t('message.project.healthyExpert.aim.stack.architecture')}}</td>
                                    <td>MVVM</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>{{$t('message.project.healthyExpert.aim.stack.editor')}}</td>
                                    <td>WebStorm; VS Code; Spyder</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>{{$t('message.project.healthyExpert.aim.stack.version_control')}}</td>
                                    <td>GitHub</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>{{$t('message.project.healthyExpert.aim.stack.apis')}}</td>
                                    <td>Okhttp,MPAndroidChart,Gson...</td>
                                </tr>

                            </table>
                        </div>
                    </div>
                </div>

                <!--系统流程图-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <h3 class="text-center red-bottom">{{$t('message.project.healthyExpert.dataflow.header')}}</h3>
                    <img src="@/assets/worksDetail/healthyExpert/dataFlow.png" class="img-fluid w-100">
                </div>

                <!--用户界面卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <div class=" mt-3">
                        <h3 class="red-bottom">用户界面</h3>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 order-1 order-md-1">
                            <img src="@/assets/worksDetail/healthyExpert/authorization.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-2">
                            <h3 class="fw-bold">{{$t('message.project.healthyExpert.interface.authentication.header')}}</h3>
                            <p>{{$t('message.project.healthyExpert.interface.authentication.content')}}</p>
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-1">
                            <h3 class="fw-bold">{{$t('message.project.healthyExpert.interface.main.header')}}</h3>
                            <p>{{$t('message.project.healthyExpert.interface.main.content')}}</p>
                        </div>
                        <div class="col-12 col-md-6 order-1 order-md-2">
                            <img src="@/assets/worksDetail/healthyExpert/home.png" class="img-fluid">
                        </div>

                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 order-1 order-md-1">
                            <img src="@/assets/worksDetail/healthyExpert/authorization.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-2">
                            <h3 class="fw-bold">{{$t('message.project.healthyExpert.interface.calorie.header')}}</h3>
                            <p>{{$t('message.project.healthyExpert.interface.calorie.content')}}</p>
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-1">
                            <h3 class="fw-bold">{{$t('message.project.healthyExpert.interface.step.header')}}</h3>
                            <p>{{$t('message.project.healthyExpert.interface.step.content')}}</p>
                        </div>
                        <div class="col-4 col-md-3 order-1 order-md-2 mx-auto">
                            <img src="@/assets/worksDetail/healthyExpert/steps.png" class="img-fluid">
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 order-1 order-md-1">
                            <img src="@/assets/worksDetail/healthyExpert/training.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-2">
                            <h3 class="fw-bold">{{$t('message.project.healthyExpert.interface.exercise.header')}}</h3>
                            <p>{{$t('message.project.healthyExpert.interface.exercise.content')}}</p>
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-1">
                            <h3 class="fw-bold">{{$t('message.project.healthyExpert.interface.water.header')}}</h3>
                            <p>{{$t('message.project.healthyExpert.interface.water.content')}}</p>
                        </div>
                        <div class="col-12 col-md-6 order-1 order-md-2 mx-auto">
                            <img src="@/assets/worksDetail/healthyExpert/water.png" class="img-fluid">
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 order-1 order-md-1">
                            <img src="@/assets/worksDetail/healthyExpert/medication.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-2">
                            <h3 class="fw-bold">{{$t('message.project.healthyExpert.interface.medication.header')}}</h3>
                            <p>{{$t('message.project.healthyExpert.interface.medication.content')}}</p>
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-1">
                            <h3 class="fw-bold">{{$t('message.project.healthyExpert.interface.sleep.header')}}</h3>
                            <p>{{$t('message.project.healthyExpert.interface.sleep.content')}}</p>
                        </div>
                        <div class="col-12 col-md-6 order-1 order-md-2 mx-auto">
                            <img src="@/assets/worksDetail/healthyExpert/sleep.png" class="img-fluid">
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 order-1 order-md-1">
                            <img src="@/assets/worksDetail/healthyExpert/heartRate.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-2">
                            <h3 class="fw-bold">{{$t('message.project.healthyExpert.interface.heartRate.header')}}</h3>
                            <p>{{$t('message.project.healthyExpert.interface.heartRate.content')}}</p>
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-1">
                            <h3 class="fw-bold">{{$t('message.project.healthyExpert.interface.period.header')}}</h3>
                            <p>{{$t('message.project.healthyExpert.interface.period.content')}}</p>
                        </div>
                        <div class="col-4 col-md-3 order-1 order-md-2 mx-auto">
                            <img src="@/assets/worksDetail/healthyExpert/period.png" class="img-fluid">
                        </div>
                    </div>

                </div>

                <!--用户反馈卡片-->
                <div class=" card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <h3 class="red-bottom">{{$t('message.project.healthyExpert.result.header')}}</h3>
                    <p>{{$t('message.project.healthyExpert.result.result')}}</p>
                    <br>
                    <p>{{$t('message.project.healthyExpert.result.significant')}}</p>

                </div>



            </div>
        </div>

        <!--Promo卡片-->
        <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
            <img src="@/assets/worksDetail/healthyExpert/healthyExpertPromo.png" class="img-fluid w-100">
        </div>

    </div>
       
</template>

<script>
import WorkHeader from '@/components/WorkHeader';
import SingleStack from "@/components/SingleStack.vue";


export default {
    components: {
        SingleStack,
        WorkHeader,
    },

};
</script>

<style lang="css" scoped>
.red-bottom {
    width: fit-content;
    border-bottom: 3px solid #FF6666;
}


table {
    border-collapse: collapse;
    width: 100%;
}
th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}
th {
    background-color: #f2f2f2;
}

li{
    margin-bottom: 30px;
}
</style>