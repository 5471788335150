<template>
  <div class="card-container my-2 w-100 h-100 bg-white overflow-hidden rounded-4 " :class="isShadow? 'shadow-lg': ''" @mouseenter="showOverlay = true" @mouseleave="showOverlay = false">
    <slot></slot> <!-- 默认插槽，允许外部传入内容 -->
    <router-link :to="toURL" class="text-decoration-none ">
      <div v-show="showOverlay" class="overlay rounded-4 border-0 h-100 overflow-auto  wow fadeIn" data-wow-duration="0.5s">
        <slot name="overlay"></slot> <!-- 具名插槽，用于传入遮罩内容 -->
      </div>
    </router-link>
    
  </div>
</template>

<script>


export default {
  props:{
    toURL: {
      type: String,
      required: true
    },
    isShadow: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      showOverlay: false,
    };
  },
};
</script>

<style lang="css" scoped>
.card-container {
  position: relative;
  margin: 0;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 65, 85, 0.9);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-inline: 10px;
  opacity: 0; /* 初始透明度为0 */
  transition: opacity 0.5s; /* 过渡效果 */
}

.overlay.fadeIn {
  opacity: 1; /* 显示时透明度为1 */
}

/* 隐藏滚动条 */
.overlay::-webkit-scrollbar {
  display: none; /* 对于WebKit浏览器 */
}

.overlay {
  -ms-overflow-style: none;  /* IE和Edge */
  scrollbar-width: none;  /* Firefox */
}

</style>
