<template >
    <button class="rounded-3 btn btn-secondary my-1 btn-sm">{{ tag }}</button>
</template>


<script>
export default {
    components: {},
    props:{
      tag:{
          type: String,
          required: true
      }
    },
    methods: {},
}
</script>