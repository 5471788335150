<template>
    <div class="mt-5 container">
       <WorkHeader :title="$t('message.project.birdWatching.title')" date="2023/07/15"/>
        <div class="row d-flex justify-content-center">
            <div class="col-12">
                <!--标题卡片-->
                <div class="card bg-light border-0 rounded-4 wow fadeIn" data-wow-duration="1.5s">
                    <div class="row">
                        <div class="col-6 d-flex flex-column justify-content-center align-items-center text-center">
                            <h1 class="fw-bold">{{ $t('message.project.birdWatching.app_name') }}</h1>
                            <p class="text-muted">{{ $t('message.project.birdWatching.title') }}</p>
                            <div class="d-none d-md-flex flex-row d-grid gap-2 mt-5 ">
                                <SingleStack :imgSrc="require('@/assets/stackLogo/node-js.png')"
                                             stackName="NODE.JS" bgc="#43853d"></SingleStack>
                                <SingleStack :imgSrc="require('@/assets/stackLogo/ejs.png')"
                                             stackName="EJS" bgc="#239120"></SingleStack>
                                <SingleStack :imgSrc="require('@/assets/stackLogo/bootstrap.png')"
                                             stackName="BOOTSTRAP" bgc="#563d7c"></SingleStack>
                                <SingleStack :imgSrc="require('@/assets/stackLogo/mongoDb.png')"
                                             stackName="MONGODB" bgc="#499D4A"></SingleStack>
                            </div>
                        </div>
                        <div class="col-6">
                            <img src="@/assets/worksDetail/birdWatching/birdWatching_matting.png" class="img-fluid">
                        </div>
                    </div>
                </div>
                <!--项目目标卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <div class="row">
                        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center text-center">
                            <h3 class="text-center red-bottom">{{$t('message.project.birdWatching.aim.header')}}</h3>
                            <p class="mt-3">{{$t('message.project.birdWatching.aim.content')}}</p>
                        </div>
                        <div class="col-12 col-lg-6 mt-2 mt-lg-0 d-flex justify-content-center">
                            <table>
                                <tr>
                                    <th>#</th>
                                    <th>{{$t('message.project.stack.type')}}</th>
                                    <th>{{$t('message.project.stack.stack')}}</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>{{$t('message.project.stack.front_end')}}</td>
                                    <td>EJS、Bootstrap、ServiceWorker</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>{{$t('message.project.stack.back_end')}}</td>
                                    <td>Node.js、Express.js、Socket.IO</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>{{$t('message.project.stack.database')}}</td>
                                    <td>MongoDB; IndexDB</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>{{$t('message.project.stack.editor')}}</td>
                                    <td>WebStorm</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>{{$t('message.project.stack.version_control')}}</td>
                                    <td>GitHub</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>


                <!--用户界面卡片-->
                <div class="card bg-light border-0 rounded-4 p-3 mt-3 wow fadeIn" data-wow-duration="1.5s">
                    <div class=" mt-3">
                        <h3 class="red-bottom">用户界面</h3>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 order-1 order-md-1 row">
                            <div class="col-4"><img src="@/assets/worksDetail/birdWatching/create_phone.png" class="img-fluid"></div>
                            <div class="col-8"><img src="@/assets/worksDetail/birdWatching/create.png" class="img-fluid"></div>
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-2">
                            <h3 class="fw-bold">{{$t('message.project.birdWatching.interface.create.header')}}</h3>
                            <p>{{$t('message.project.birdWatching.interface.create.content')}}</p>
                        </div>
                    </div>


                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-1">
                            <h3 class="fw-bold">{{$t('message.project.birdWatching.interface.chat.header')}}</h3>
                            <p>{{$t('message.project.birdWatching.interface.chat.content')}}</p>
                        </div>
                        <div class="col-12 col-md-6 order-1 order-md-2">
                            <img src="@/assets/worksDetail/birdWatching/chat.png" class="img-fluid">
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 order-1 order-md-1">
                            <img src="@/assets/worksDetail/birdWatching/identification.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-2">
                            <h3 class="fw-bold">{{$t('message.project.birdWatching.interface.DBPedia.header')}}</h3>
                            <p>{{$t('message.project.birdWatching.interface.DBPedia.content')}}</p>
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-1">
                            <h3 class="fw-bold">{{$t('message.project.birdWatching.interface.location.header')}}</h3>
                            <p>{{$t('message.project.birdWatching.interface.location.content')}}</p>
                        </div>
                        <div class="col-12 col-md-6 order-1 order-md-2">
                            <img src="@/assets/worksDetail/birdWatching/location.png" class="img-fluid">
                        </div>
                    </div>

                    <div class="row mt-5 wow fadeIn" data-wow-duration="1.5s">
                        <div class="col-12 col-md-6 order-1 order-md-1">
                            <img src="@/assets/worksDetail/birdWatching/onBoarding.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex flex-column justify-content-center text-center order-2 order-md-2">
                            <h3 class="fw-bold">{{$t('message.project.birdWatching.interface.offline.header')}}</h3>
                            <p>{{$t('message.project.birdWatching.interface.offline.content')}}</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
       
</template>

<script>
import WorkHeader from '@/components/WorkHeader';
import SingleStack from "@/components/SingleStack.vue";

export default {
    components: {
        SingleStack,
        WorkHeader,
    },

};
</script>

<style lang="css" scoped>

table {
    border-collapse: collapse;
    width: 100%;
}
th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}
th {
    background-color: #f2f2f2;
}

li{
    list-style-type: circle;
}

</style>